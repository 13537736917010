<template>
	<div class="home-container">
		<div class="linear-bg">
				<div style="display: flex;
				flex: 1;
				align-items: center;
				justify-content: start;
				height: 100%;">

				<div><img src="/img/bs1/a5.jpg" style="height: 50px;margin-top: 5px;margin-left: 20px;margin-right: 50px;"/></div>
				<div style="display: flex;
    align-items: center;
    justify-content: space-between;">
					<input type="text" value="" placeholder="Tìm kiếm" autocomplete="off" class="sc-8sshey-5 fbjppU">
					<span class="sc-1vo1n72-0 sc-8sshey-10 fpHzhT dfGAzM"></span>
				</div>
				</div>
		</div>
		
		<div class="home-scroll">
			<!-- <div class="banner">
				<swiper class="banner_swiper" :options="bannerSwiperOption">
					<swiper-slide v-for="(v,key) in banners" :key="key">
						<van-image class="banner_img" round :src="v.url">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
					</swiper-slide>
				</swiper>
			</div> -->
			<!-- <div class="notice-bar">
				<van-notice-bar class="notice-swipe" left-icon="bullhorn-o" background="#ffffff" color="#f487e0"
					:text="this.notice" />
				<div class="linear-gradient"></div>
			</div> -->
			<!-- <div class="hot-game">
				<div class="hot-title-div">
					<div>
						<span>{{$t('index.task')}}</span>
					</div>
					<div @click="gotoMenu('/Game')">
						<span>{{$t('index.more')}}</span>
						<van-icon name="arrow" color="#979799" />
					</div>
				</div>
				<div class="hot-items-div">
					<van-grid :border=false :column-num="4" :icon-size="20">
						<van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
							<van-image class="game_item_img" :src="v.ico">
								<template v-slot:loading>
									<van-loading type="circular" />
								</template>
							</van-image>
							<span>{{v.name}}</span>
						</van-grid-item>
					</van-grid>
				</div>
			</div> -->
			<van-pull-refresh pulling-text="Sao chép nhanh quy trình thả xuống"
				loosing-text="Bản sao nhắc nhở của quá trình phát hành" loading-text="Đang tải bản sao nhắc quá trình"
				success-text="Làm mới bản sao lời nhắc thành công" v-model="isLoading" @refresh="onRefresh">
				<div class="hot-recommend">
					<div class="hot-title-div">
						<!-- <div>
							<span>{{$t('index.hot')}}</span>
						</div> -->
						<!-- <div> -->
							<!-- <span @click="gotoMenu('/Videos')">{{$t('index.more')}}</span> -->
							<!-- <span>{{$t('index.more')}}</span>
							<van-icon name="arrow" color="#979799" />
						</div> -->
					</div>
					<div class="movie_list_0">
						<swiper class="movie_swiper" :options="movielistSwiperOption">
							<swiper-slide v-for="(v,key) in movielist_0" :key="key">
								<div style="padding: 8px !important;background: #ffff !important;margin-right: 10px;height: 260px;">
									<van-image class="movie_cover" @click="toPlayVideo(v.id)" round :src="v.cover">
									<template v-slot:loading>
										<van-loading type="circular" />
									</template>
								</van-image>
								<div data-v-76d8f91a="" class="sc-1vo1n72-1 cZhfaC"><span data-v-76d8f91a="" style="font-weight: 700;
	color: #fff;
	font-size: 8PX;">Nổi bật</span></div>
								<!-- <img class="hot" v-if="key === 0" src="/img/home/no1.png">
								<img class="hot" v-if="key === 1" src="/img/home/no2.png">
								<img class="hot" v-if="key === 2" src="/img/home/no3.png"> -->
								<div class="movie-list-item-bottom">
									<div class="movie-time-div">
										<span style="
    overflow: hidden;display: -webkit-box;    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;font-size: 14PX;">Lao động nước ngoài:</span>
		<span style="
    overflow: hidden;display: -webkit-box;    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;font-size: 14PX;">Ngành nghề:</span>
		<span style="
    overflow: hidden;display: -webkit-box;    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;font-size: 14PX;">{{v.title}}</span>
			<span style="
    overflow: hidden;display: -webkit-box;    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;font-size: 14PX;">Mức lương : </span>
			<span style="
    display: -webkit-box;    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;font-size: 14PX;">{{v.vod_play_url}}</span>
										<!-- <div class="van-count-down">{{v.time}}</div> -->
										<!-- <ul style="    margin-top: 20PX;"></ul> -->
										<!-- <div class="price">{{v.vod_play_url}}</div> -->

										<ul data-v-76d8f91a="" class="sc-z4q3vq-8 lpuxSi">
											<li data-v-76d8f91a="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl" style="margin-right: 26px;">
												<span data-v-76d8f91a="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc" style="padding-left: 0px;    margin-right: 0PX;"></span>
												<span data-v-76d8f91a="" style="width: 16px;    font-size: 0.65rem;    padding-left: 0px;">Hôm nay</span>
											</li>
											<li data-v-76d8f91a="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
												<span data-v-76d8f91a="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb" style="padding-left: 0px;    margin-right: 0PX;">

												</span>
												<span data-v-76d8f91a="" style="width: 82px;   padding-left: 0px;  font-size: 0.65rem; ">{{v.dizhi}}</span>
											</li>
										</ul>
									</div>
								</div>
								</div>
							</swiper-slide>
						</swiper>
					</div>
					<div class="hot-title-div">
						<!-- <div>
							<span>{{$t('index.more')}}</span>
						</div> -->
						<!-- <div @click="gotoMenu('/Videos')"> -->
							<!-- <div>
							<span>{{$t('index.recmonmand')}}</span>
							<van-icon name="arrow" size="25" color="#979799" />
						</div> -->
					</div>
					<div class="movie_list_1">
						<div class="movie-list-item" v-for="(v,key) in movielist_1" :key="key"
							@click="toPlayVideo(v.id)">
							<div style="position: relative;display: flex;width: 100%;flex-direction: row;">
								<div>
									<van-image class="cover_img" round :src="v.cover">
									<template v-slot:loading>
										<van-loading type="circular" />
									</template>
									</van-image>
									<div data-v-76d8f91a="" class="sc-1vo1n72-1 gCoOOb"><span data-v-76d8f91a="">VIP S</span></div>
									<!-- <div class="movie-list-item-bottom"> -->
										<!-- <div class="movie-time-div"> -->
											<!-- <span>{{v.title}}</span>
											<span>{{$t('video.play')}}:{{v.count}}</span> -->
												<!-- <span>{{v.vod_name}}</span>
												<div class="van-count-down">{{v.vod_name}}</div> -->
												<!-- <div class="van-count-down">{{v.time}}</div> -->
										<!-- </div> -->
									<!-- </div> -->
								</div>
								<div style="width: 100%; display: flex; flex-direction: column;gap: 2PX;    margin-left: 10px;">
									<div style="width: 210px;"><span class="s1">Lao động nước ngoài:</span></div>
									<div style="width: 210px;"><span class="s1">Ngành nghề:</span></div>
									<div style="width: 210px;"><span class="s1">{{v.vod_name}}</span></div>
									<div style="width: 210px;"><span class="s1">Mức lương:</span></div>
									<div style="width: 210px;"><span class="s1">{{v.vod_play_url}}</span></div>
									<!-- <div  style="width: 220px;"><span class="s2">{{v.vod_play_url}}</span></div> -->
									<div class="movie-time-div">
										
										<!-- <div class="van-count-down">{{v.time}}</div> -->
								

										<ul data-v-76d8f91a="" class="sc-z4q3vq-8 lpuxSi">
											<li data-v-76d8f91a="" class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl" style="margin-right: 26px;">
												<span data-v-76d8f91a="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc" style="padding-left: 0px !important;    margin-right: 0PX !important;"></span>
												<span data-v-76d8f91a="" style="width: 38px;    font-size: .75rem;">Hôm nay</span>
											</li>
											<li data-v-76d8f91a="" class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx">
												<span data-v-76d8f91a="" class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb" style="padding-left: 0px;    margin-right: 0PX;">

												</span>
												<span data-v-76d8f91a="" style="width: 170px;    font-size: .75rem;">{{v.dizhi}}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
							
							
						</div>
						<!-- <div class="hot-recommend-more" @click="gotoMenu('/Videos')">{{$t('index.more')}}</div> -->
						<div class="hot-recommend-more">{{$t('index.more')}}</div>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				notice: this.$t("index.loading"),
				banners: [{}],
				basicData: [],
				gameitem: [{}, {}, {}, {}],
				movielist_0: [{}, {}, {}, {}],
				movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
				isLoading: false,
				movielistSwiperOption: {
					slidesPerView: 'auto',
					spaceBetween: 0,
					slidesPerGroup: 1,
				},
				bannerSwiperOption: {
					effect: 'coverflow',
					grabCursor: true,
					centeredSlides: true,
					slidesPerView: 'auto',
					speed: 800,
					autoplay: true,
					coverflowEffect: {
						rotate: 50,
						stretch: 10,
						depth: 100,
						modifier: 1,
						slideShadows: true
					}
				}
			};
		},
		methods: {
			gotoMenu(router) {
				this.$router.replace(router)
			},
			toLottery(key, id) {
				if (!localStorage.getItem('token')) {
					this.$router.push({
						path: '/Login'
					})
				} else {
					this.$router.push({
						path: '/Lottery?key=' + key + "&id=" + id
					})
				}

			},
			toPlayVideo(id) {
				if (!localStorage.getItem('token')) {
					this.$router.push({
						path: '/Login'
					})
				} else {
					console.log(id);
					// this.$router.push({
					// 	path: '/PlayVideo?id=' + id
					// })
				}
			},
			onRefresh() {
				setTimeout(() => {
					this.getBasicConfig();
					this.isLoading = false;
					this.$toast("Làm mới thành công");
				}, 500);
			},
			getBasicConfig() {
				this.$http({
					method: 'get',
					url: 'sys_config'
				}).then(res => {
					this.basicData = res.data;
					console.info(res)
					this.getNotice(this.basicData); //获取公告
					this.getBanner(this.basicData); //获取banner
					this.getGameItem(); //获取首页游戏列表
					this.getMovieList_0(this.basicData); //获取首页视频0
					this.getMovieList_1(this.basicData); //获取首页视频1
				})

			},
			getNotice(data) {
				this.notice = data.notice;
			},
			getGameItem() {
				this.$http({
					method: 'get',
					url: 'lottery_hot'
				}).then(res => {
					this.gameitem = res.data
				})
			},
			getMovieList_0(data) {
				this.movielist_0 = data.movielist_0
			},
			getMovieList_1(data) {
				this.movielist_1 = data.movielist_1
			},
			getBanner(data) {
				this.banners = data.banners;
			}
		},
		mounted() {

		},
		created() {
			this.getBasicConfig();
		}
	}
</script>

<style lang='less' scoped>
	@notice-bar-size: 30px;
	@movie-list-item-bottom-size: 25px;

	// .swiper-slide{
	// 	padding: 8px !important;
    // 	background: #ffff !important;
	// }

	.s1{
		margin: 0;
    display: block;
    // font-size: 1.125rem;
    // font-weight: 400;
    font-size: 14PX;
    // line-height: 24PX;
    // max-height: 48PX;
    // margin-bottom: 4PX;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
	}

	.s2{
		font-style: normal;
    font-weight: 700;
    font-size: 16PX;
    line-height: 24PX;
    color: #d14200;
    min-height: 24PX;
    min-width: 80PX;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
	}

	.gCoOOb{
    position: absolute;
    padding: 0 8PX;
    border-radius: 4PX;
    background: #ff641e;
    top: 4PX;
    left: 4PX;
    height: 18PX;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gCoOOb span {
    font-size: 8PX;
	color: #fff;
}

	.dkCEqR {
    padding: 0 6PX;
    background: #fff;
    height: 50PX;
}
.hqEtQl {
    margin-right: 5PX;
}
.joVhly {
    display: flex;
    // align-items: flex-end;
	align-items: stretch;
}
.joVhly span {
    display: inline-block;
    line-height: 16PX !important;
}
.hLowUx span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bGiBvb{
    width: 16PX !important;
    height: 16PX !important;
	min-height: 16PX !important;
    background-position: -45PX -46PX;
    margin-right: 5PX;
}
.joVhly span {
    display: inline-block;
    line-height: 16PX !important;
}

.gVZxfc{
	min-height: 16PX !important;
    width: 16PX !important;
    height: 16PX !important;
    background-position: -20PX -46PX;
    margin-right: 5PX;
}
.lpuxSi{
	margin-top: 3PX;
    display: flex;
    align-items: center;
    color: #717375;
    font-size: 13PX !important;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    margin-top: 2PX;
    min-height: 22PX !important;
}

.cZhfaC {
	
    position: absolute;
    padding: 0 8PX;
    border-radius: 4PX;
    background: #bf1d28;
	top: 13PX;
    left: 13PX;
    height: 18PX;
    display: flex;
    justify-content: center;
    align-items: center;
}
.price{
	margin: 5PX 0 0;
    // color: #d14200;
    min-height: 24PX;
    min-width: 80PX;
    // font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13PX;
	// font-size: 14px;
	width: 280px;
}
.dkCEqR {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
    max-width: 460PX;
}

	.dfGAzM {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    right: 14PX;
	top: 30px;
    width: 20PX;
    height: 20PX;
    background-position: -175PX -20PX;
}

.fpHzhT {
    background-color: transparent;
    display: inline-block;
    background-image: url(/img/bs/common.png);
}

	.fbjppU {
    padding-left: 8PX;
    padding-right: 60PX;
	height: 36PX;
    padding-right: 60PX;
    font-size: 16PX;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
	width: 100%;
    outline: none;
    background-color: #fff;
    border: 1PX solid #ecedef;
    border-radius: 24PX;
    color: var(--cement-darkest);
    font-weight: 400;
    z-index: 100;
}


	.linear-bg {
		height: 100px;
		// background: linear-gradient(270deg, #988fba, #f487e0);
		// background: linear-gradient(90deg, #500cfd, #9e9ce7);
		
	}

	.home-container {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: #ffffff;
	}

	.linear-gradient {
		width: 100%;
		height: 2px;
		background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
	}

	::v-deep .van-notice-bar__left-icon,
	.van-notice-bar__right-icon {
		min-width: 40px;
	}

	.notice-swipe {
		width: calc(100% - 50px);
		height: 85px;
		font-size: @notice-bar-size;
	}

	::v-deep .van-icon-bullhorn-o::before {
		transform: scale(2.5);
	}

	.banner {
		width: 100%;
		margin-top: -23%;
	}

	.banner_swiper {
		height: 100%;
		width: 100%;

		.swiper-slide {
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 220px;
			height: 300px;
			text-align: center;
			font-weight: bold;
			font-size: 20px;
			background-color: #ffffff;
			background-position: center;
			background-size: cover;
			color: #ffffff;
		}
	}

	::v-deep .swiper-container-3d .swiper-slide-shadow-left {
		background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
	}

	::v-deep .swiper-container-3d .swiper-slide-shadow-right {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
	}

	.banner_img {
		border-radius: 10px;
		width: 100%;
		height: 100%;
	}

	.hot-game {
		width: 100%;
		height: 100%;
	}

	.hot-title-div {
		width: calc(100% - 50px);
		margin: 0 auto;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.hot-title-div>div:first-child {
		width: 430px;
	}

	.hot-title-div div {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.hot-title-div>div:nth-child(2) span {
		font-size: 20px;
		color: #979799;
	}

	.hot-title-div>div:first-child span {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 10px;
		font-weight: 700;
		color: #000;
	}

	.hot-title-div>div:first-child span {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 28px;
		font-weight: 700;
		color: #000;
	}

	.hot-title-div>div:nth-child(2) span {
		font-size: 25px;
		color: #979799;
	}

	.hot-title-div>div:first-child span:before {
		content: "";
		display: block;
		width: 5px;
		height: 30px;
		background-color: #500cfd;
		border-radius: 1px;
		margin-right: 5px;
	}

	.hot-game .hot-items-div {
		margin-top: -3px;
	}

	.hot-game .hot-items-div span {
		margin-top: 10px;
		font-size: 28px;
		color: #000;
	}

	.hot-recommend {
		width: 100%;
		flex: 1;
		background-color: #f2f2f5;
	}

	.movie_swiper {
		.swiper-slide {
			width: 47%;
		}
	}

	.movie_list_0 {
		width: calc(100% - 50px);
		margin: 0 auto;
	}

	.movie_cover {
		border-radius: 2%;
		// width: 550px;
		// height: 330px;
		width: 144PX;
    height: 108PX;
	}

	.movie_list_0 .movie-list-item-bottom {
		position: relative;
		width: 550px;
		// bottom: 43px;
	}

	.movie_list_0 .movie-list-item-bottom .movie-time-div {
		// background-color: rgba(0, 0, 0, .4);
		
	}

	.movie_list_0 .movie-list-item-bottom>div {
		width: 100%;
		display: flex;
		// align-items: center;
		// justify-content: space-between;
		flex-direction: column;
	}

	.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
		font-size: 28px;
		color: #fff;
	}

	.movie_list_0 .movie-time-div {
		// color: #fff;
		border-radius: 0 0 10px 10px;
	}

	.movie_list_0 .movie_swiper .hot {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 80px;
	}

	.movie_list_0 span {
		font-size: 30px;
	}

	.movie_list_1 {
		// padding: 20px;
		display: flex;
		// width: calc(100% - 50px);
		margin: 0 auto;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		flex-direction: column;
		background: #fff;
	}

	.movie_list_1 .movie-list-item .cover_img {
		border-radius:2px;
		// width: 335px;
		// height: 290px;
		width: 144PX !important;
    height: 108PX !important;
	}

	.home-scroll {
		padding-bottom: 110px;
	}

	.movie_list_1 .movie-list-item {
		padding: 16PX 8PX;
		border-top: 1PX solid #ecedef;
		margin-bottom: -10px;
	}

	.movie_list_1 img{
		width: 144PX !important;
    height: 108PX !important;
	}

	.movie_list_1 .movie-list-item-bottom {
		position: relative;
		width: 335px;
		bottom: 42px;

	}

	.movie_list_1 .movie-list-item:nth-child(odd) {
		margin-right: 25px;
	}

	.movie_list_1 .movie-list-item-bottom .movie-time-div {
		background-color: rgba(0, 0, 0, .4);
	}

	.movie_list_1 .movie-list-item-bottom>div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
		color: #fff;
	}

	.movie_list_1 .movie-time-div {
		color: #fff;
		border-radius: 0 0 10px 10px;
		height: 35px;
	}

	.movie_list_1 .movie_swiper .hot {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 5px;
	}

	.movie_list_1 .movie-list-item .movie-time-div span:first-child {
		overflow: hidden;
		white-space: nowrap;
		width: 180px;
		padding-left: 8px;
		font-size: 25px;
	}

	.movie_list_1 .movie-list-item .movie-time-div span:last-child {
		overflow: hidden;
		white-space: nowrap;
		width: 0px;
		padding-right: 110px;
		font-size: 22px;
	}

	.movie_list_0 .movie-time-div span:first-child {
		// overflow: hidden;
		// white-space: nowrap;
		width: 280px;
		padding-left: 10px;
		font-size: 25px;
		
	}

	.movie_list_0 .movie-time-div span{
		// overflow: hidden;
		// white-space: nowrap;
		width: 280px;
		padding-left: 10px;
		font-size: 25px;
		// min-height: 40PX;
    font-size: 14PX;
    // line-height: 20PX;
	}

	.hot-recommend-more {
		width: 100%;
		padding-bottom: 20px;
		text-align: center;
		color: #979799;
		font-size: 30px;
	}

	.hot-items-div .game_item_img {
		width: 100px;
		height: 100px;
	}

	::v-deep .hot-items-div .game_item_img .van-image__img {
		border-radius: 20px;
		
	}

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}
</style>