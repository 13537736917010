import { render, staticRenderFns } from "./Notice.vue?vue&type=template&id=6ab3e928&scoped=true&"
import script from "./Notice.vue?vue&type=script&lang=js&"
export * from "./Notice.vue?vue&type=script&lang=js&"
import style0 from "./Notice.vue?vue&type=style&index=0&id=6ab3e928&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ab3e928",
  null
  
)

export default component.exports